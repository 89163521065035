import { Project, Task } from '@alborea/portal';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreCollectionGroup, Query, QueryGroupFn } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { FilterFormValues, PaginationState, QueryResult, QueryFields } from '../carnet-list/carnet-list.interface';
import { CarnetDocument } from '../interfaces/carnet-document.interace';
import { ModelDocument } from '../interfaces/model-document.interface';

@Injectable({ providedIn: 'root' })
export class FirestoreHelperService {

  constructor(private afs: AngularFirestore) { }

  public getProjectCollectionById(projectId: number): AngularFirestoreCollection<CarnetDocument> {
    return this.afs.collection(`${environment.projectsCollectionRoot}/${projectId}/${environment.projectsCollectionEnd}`); 
  }

  public getModelCollectionByUserId(userId: number): AngularFirestoreCollection<ModelDocument> {
    return this.afs.collection(`${environment.userCollectionRoot}/${userId}/${environment.userCollectionEnd}`);
  }

  public queryCollectionGroup(queryFunction: QueryGroupFn<CarnetDocument>): AngularFirestoreCollectionGroup<CarnetDocument> {
    return this.afs.collectionGroup(environment.projectsCollectionEnd, queryFunction);
  }

  // Tranformer un FilterFrom dans des pairs champ : valeur
  public getQueryFields(formValues: FilterFormValues): QueryFields {
    return Object.entries(formValues).reduce((acc, val) => {
      const [ field, value ]: [ string, Task | Project ] = val;
      acc[`${field}Id`] = value?.id;
      return acc;
    }, {} as QueryFields);
  }

  // Appliquer des conditions where(champ, ‘==’, valuer) enchainées 
  // à une requète existance à partir des pairs champ : valeur
  public whereFromQueryFields(
    queryFields: QueryFields,
    ref: Query,
    defaultFnc: (ref: Query) => Query
  ): Query {
    return Object.values(queryFields).every(qf => !qf)
      ? defaultFnc(ref)
      : Object.entries(queryFields).reduce((ref, term) => {
          const [ field, value ]: [ string, number ] = term;
          return value ? ref.where(field, '==', value) : ref
        }, ref);
  }

  // Paginer une requête à partir de l’état de pagination et les résultats de la requête précédant
  public applyPagination(
    pagination: PaginationState,
    previousQuery: QueryResult,
    limit: number,
    ref: Query
  ): Query {
    switch (pagination.pageDirection) {
      case 'inc':
        ref = ref.startAfter(previousQuery.last);
        break;
      case 'dec':
        ref = ref.endBefore(previousQuery.first);
        break;
      case 'repeat':
        ref = ref.startAt(previousQuery.first);
        break;
    }
    return pagination.pageDirection === 'dec' ? ref.limitToLast(limit) : ref.limit(limit);
  }

  // Génère une fonction qui retourne une requête paginée
  public buildQueryFunction(
    queryFields: QueryFields,
    pagination: PaginationState,
    previousQuery: QueryResult,
    limit: number,
    defaultQueryFnc: (ref: Query) => Query,
  ): (ref: Query) => Query<any> {
    return ref => this.applyPagination(
      pagination,
      previousQuery,
      limit,
      this.whereFromQueryFields(
        queryFields,
        this.generateDefaultRequest(ref),
        defaultQueryFnc,
      ),
    );
  }

  // Requête de base pour toutes les requêtes subsequentes - à abstraire quand necessaire.
  private generateDefaultRequest(ref: Query): Query {
    return ref.orderBy('activityDateTimestamp', 'desc');
  }
}
