<div fxFlex fxLayout="column" fxLayoutAlign="center center">
    <h1 mat-dialog-title>
        <ng-container [ngSwitch]="promiseStatus">
            <span *ngSwitchCase="'PENDING'">
                En cours de soumission...
            </span>
            <span *ngSwitchCase="'SUCCESS'">
                Soumission réussie!
            </span>
            <span *ngSwitchCase="'FAILURE'">
                Un problème est servenu. Veuillez réessayer.
            </span>
        </ng-container>
    </h1>
    
    <div>
        <ng-container [ngSwitch]="promiseStatus">
            <mat-spinner *ngSwitchCase="'PENDING'"></mat-spinner>
        </ng-container>
    </div>
    
    <div mat-dialog-actions>
        <button 
            mat-raised-button
            color="accent"
            [disabled]="promiseStatus === 'PENDING'"
            [mat-dialog-close]="promiseStatus">
            <ng-container [ngSwitch]="promiseStatus">
                <span *ngSwitchCase="'PENDING'">
                    ...
                </span>
                <span *ngSwitchCase="'SUCCESS'">
                    Fermer
                </span>
                <span *ngSwitchCase="'FAILURE'">
                    Réessayer
                </span>
            </ng-container>
        </button>
    </div>
</div>