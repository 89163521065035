import { toQueryParams } from '@alborea/loopback';
import { BaseAuthService } from '@alborea/portal';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as config from '../../assets/config/config.json';

export interface SSEDocument {
  id: number;
  description: string;
  lastUpdatedDate: Date;
  fileURL: string;
  isActived: boolean;
  categoryId: number;
}

@Injectable({ providedIn: 'root' })
export class SSEDocumentService {

  constructor(
    private http: HttpClient,
    private authService: BaseAuthService,
  ) { }

  documentRoot = `${config.portalURL}/documents`;
  downloadRoot = `${config.portalURL}/containers/documents/download/`;

  // Il n'y a pas de façon de distinguer les documents SSE au niveau de DB.
  // J'utilise la description au lieu de l'id pour assurer la continuité suite à la m-à-j du doc
  sseDocumentFlags = [
    'Analyse de la sécurité des tâches',
    'Rapport et analyse accident / incident',
    'Politique SSE',
    'Manuel SSE, Février 2021',
  ];

  getDocumentList(): Observable<SSEDocument[]> {
    return this.http.get<SSEDocument[]>(
      this.documentRoot +
      toQueryParams('filter', {
        where: { 
          isActived: true,
          description: { inq: this.sseDocumentFlags }
        }
      })
    );
  }

  downloadDocument(document: SSEDocument): void {
    window.open(
      this.downloadRoot + document.fileURL + toQueryParams(
        'access_token',
        this.authService.userToken.id
      ),
      '_blank'
    );
  }

}
