<ng-container *ngIf="carnetFormState.includes('carnetCreate'); else modelTemplate">
    <p>
        Vous soumettez un carnet d'analyse de risque pour :
    </p>
    
    <mat-card>
        <mat-card-content>
            <ng-container *ngIf="informationValue$ | async; let result">
                <p>
                    <strong>Code de projet :</strong> 
                    {{ result.project?.code }}
                </p>
                <p>
                    <strong>Lot :</strong> 
                    {{ result.task?.code }} - {{ result.task?.name }}
                </p>
                <p>
                    <strong>Date des activités :</strong> 
                    {{ result.date | date:'dd/MM/yyyy' }}
                </p>
            </ng-container>
            <ng-container *ngIf="activitiesValue$ | async; let result">
                <p>
                    <strong>Activités à réaliser :</strong>
                    <span *ngFor="let activity of result; last as isLast">
                        {{ activity }}{{ isLast ? '' : ', ' }}
                    </span>
                </p>
            </ng-container>
        </mat-card-content>
    </mat-card>
    
    <p>
        Merci de valider les informations fournies aux étapes précédentes.
        Vous ne pourrez pas les modifier après les avoir soumises.
    </p>
    <p>
        En cliquant sur "Soumettre", vous vous engagez à respecter l'analyse de risque soumise.
    </p>
    <p>
        Si les conditions du projet changent, ou si de nouveaux risques se présentent, vous 
        devez compléter le carnet d'analyse de nouveau.
    </p>
</ng-container>

<ng-template #modelTemplate>
    <form [formGroup]="formRef">
        <p>Veuillez saisir le nom du modèle - vous pourrez le modifier plus tard.</p>
        <mat-form-field appearance="outline">
            <mat-label>Nom du modèle</mat-label>
            <input matInput formControlName="modelName" />
            <mat-error>Nom du modèle réquis.</mat-error>
        </mat-form-field>
    </form>
</ng-template>