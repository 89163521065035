import { Department, Profile } from '@alborea/portal';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { DepartmentService } from 'src/app/services/department.service';

@Component({
  selector: 'app-employee-picker',
  templateUrl: './employee-picker.component.html',
  styleUrls: ['./employee-picker.component.scss']
})
export class EmployeePickerComponent implements OnInit {
  
  @Input() departmentControl: FormControl;
  @Input() authorControl: FormControl;

  constructor(private departmentService: DepartmentService) { }

  deparmentList$ = this.departmentService.getDepartmentList();
  profileList$: Observable<Profile[]>;

  ngOnInit(): void {
    this.profileList$ = this.departmentControl
      .valueChanges
      .pipe(
        startWith(this.departmentControl.value),
        map((value: Department) => value?.profiles.filter(profile => !profile.isBlocked))
      );
  }

  selectCompare(a: Department | Profile, b: Department | Profile): boolean {
    return a?.id === b?.id;
  }

  removeAuthorFilter(): void {
    this.authorControl.setValue(null);
  }

  removeDeparmentFilter(): void {
    this.authorControl.setValue(null);
    this.departmentControl.setValue(null);
  }

}
