<form [formGroup]="form">
    <mat-form-field appearance="outline">
        <mat-label>Projet</mat-label>
        <mat-select 
            formControlName="project" 
            *ngIf="carnetFormState !== 'carnetRead' else displayProject"
            [compareWith]="selectCompare"
        >
            <mat-option *ngFor="let project of projectListObservable | async" [value]="project">
                {{project.code}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="carnetFormState.includes('carnetCreate')">
            Veuillez saisir le projet
        </mat-error>
        <ng-template #displayProject>
            <input matInput [value]="form.value.project.code" disabled="true">
        </ng-template>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Lot</mat-label>
        <mat-select 
            formControlName="task" 
            *ngIf="carnetFormState !== 'carnetRead' else displayTask"
            [compareWith]="selectCompare"
        >
            <mat-option *ngFor="let task of taskListObservable | async" [value]="task">
                {{ task.name }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="carnetFormState.includes('carnetCreate')">
            Veuillez saisir le lot
        </mat-error>
        <ng-template #displayTask>
            <input matInput type="text" [value]="form.value.task.name" disabled="true">
        </ng-template>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</form>
