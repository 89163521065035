<div fxFlex>
    <mat-card>
        <div>
            <button 
                (click)="createModel()"
                mat-raised-button
                color="accent"
            >
                Créer un nouveau modèle
            </button>
        </div>
        <app-model-list></app-model-list>
    </mat-card>
</div>
