import { BaseAuthService, Project, Task } from '@alborea/portal';
import { Component, OnInit } from '@angular/core';
import { Query } from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CarnetDocument } from '../interfaces/carnet-document.interace';
import { FirestoreHelperService } from '../services/firestore-helper.service';
import { ProjectHelperService } from '../services/project-helper.service';
import { FilterFormValues, PaginationDirection, PaginationState, QueryResult } from './carnet-list.interface';

@Component({
  selector: 'app-carnet-list',
  templateUrl: './carnet-list.component.html',
  styleUrls: ['./carnet-list.component.scss']
})
export class CarnetListComponent implements OnInit {

  readonly QUERY_LIMIT = 10;
  // Pas de façon de distinguer les membres du comité au niveau de la base de données - temporaire
  readonly SSE_MEMBER = [23, 61, 80, 126, 166, 171, 294, 354]
    .includes(this.authService.userInformation.user.id);
  readonly DEFAULT_PAGE_STAGE: PaginationState = { currentPage: 0, pageDirection: null };
  readonly displayedColumns = [ 'projectName', 'taskName', 'submissionDate', 'activityDate', 'authorName' ];
  readonly defaultQuery = (ref: Query) => ref.where('authorId', '==', this.authService.userInformation.user.id);

  form = new FormGroup({
    project: new FormControl(),
    task: new FormControl(),
    author: new FormControl(),
    department: new FormControl(),
  });
  previousQueryResult: QueryResult = { first: 0, last: 0, count: 0 };
  paginationState: PaginationState = { ...this.DEFAULT_PAGE_STAGE };

  projectList$: Observable<Project[]> = this.projectService.getProjectListObservable();
  taskList$: Observable<Task[]>;
  startValue$ = new BehaviorSubject<FilterFormValues>({ project: null, task: null, author: null, deparment: null });

  carnetList$ = merge(this.form.valueChanges, this.startValue$)
    .pipe(
      tap(val => 'paginated' in val || (this.paginationState = { ...this.DEFAULT_PAGE_STAGE })),
      switchMap((value: FilterFormValues) => {
        return this.firestoreService
          .queryCollectionGroup(
            this.firestoreService.buildQueryFunction(
              this.firestoreService.getQueryFields({project: value.project, task: value.task, author: value.author }),
              this.paginationState,
              this.previousQueryResult,
              this.QUERY_LIMIT,
              this.defaultQuery,
            ),
          ).valueChanges();
        }
      ),
      tap(value => {
        this.previousQueryResult.count = value.length;
        if (value.length) {
          this.previousQueryResult.first = value[0].activityDateTimestamp;
          this.previousQueryResult.last = [...value].pop().activityDateTimestamp;
        }
      }),
    )

  constructor(
    private authService: BaseAuthService,
    private firestoreService: FirestoreHelperService,
    private projectService: ProjectHelperService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    // Assurer que taskLisk$ et form.controls.taskList sont à jour suite au changement de projet.
    // Gestion des $ pourrait être integrée dans le carnetList$ rxjs pipe.
    this.form.controls.project.valueChanges.subscribe(value => {
      this.form.patchValue({ task: null }, { emitEvent: false });
      if (value) {
        this.taskList$ = this.projectService.getTaskListObservable(value.id);
      }
    })
  }

  formLoaded(formValue: FilterFormValues): void {
    this.startValue$.next(formValue);
  }

  removeProjectFilter(): void {
    this.form.patchValue({ task: null, project: null })
    this.taskList$ = null;
  }

  removeTaskFilter(): void {
    this.form.patchValue({ task: null });
  }

  onCarnetSelection(row: CarnetDocument): void {
    this.router.navigate(['visualiser'], {
      state: {
        carnetRef: row,
        carnetFormState: 'carnetRead',
      }
    });
  }

  handlePagination(direction: PaginationDirection): void {
    direction === 'inc' ? ++this.paginationState.currentPage : --this.paginationState.currentPage;
    this.paginationState.pageDirection = this.previousQueryResult.count ? direction : 'repeat';
    this.startValue$.next({...this.form.value, paginated: null});
  }

  selectCompare(a: Project | Task, b: Project | Task): boolean {
    return a?.id === b?.id;
  }
}
