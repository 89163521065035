import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss']
})
export class InfoButtonComponent {

  @Input() buttonClass: string;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() text: string;

  @Input() set type(style: 'warn' | 'add') {
    switch (style) {
      case 'warn':
        this.icon = 'warning';
        this.iconColor = 'warn';
        this.buttonClass = 'error mat-error';
        break;
      case 'add':
        this.icon = 'add_box';
        this.iconColor = 'accent';
        break;
      default:
        throw new Error(`Invalid type ${style} provided to info-button.`);
    }
  }
}
