import { Component } from '@angular/core';
import { contactInfo } from './contact-details';


@Component({
  selector: 'app-ssecontact',
  templateUrl: './ssecontact.component.html',
  styleUrls: ['./ssecontact.component.scss']
})
export class SSEContactComponent {
  contactInfo = contactInfo;
}
