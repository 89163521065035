import { 
  AfterViewInit, 
  ChangeDetectionStrategy, 
  ChangeDetectorRef, 
  Component, 
  ComponentFactoryResolver, 
  EventEmitter, 
  Injector, 
  Input, 
  Output, 
  QueryList, 
  ViewChildren, 
  ViewContainerRef 
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CarnetFormState, CarnetPage, CARNET_FORM_STATE, NULLABLE_FORM_REF } from 'src/app/global-tools';
import { CarnetDocument } from 'src/app/interfaces/carnet-document.interace';
import { SubmitDialogComponent } from '../submit-dialog/submit-dialog.component';
import { filter, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-stepper-base',
  templateUrl: './stepper-base.component.html',
  styleUrls: ['./stepper-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperBaseComponent implements AfterViewInit {

  @ViewChildren('componentOutlet', {read: ViewContainerRef}) viewContainerList: QueryList<ViewContainerRef>;
  @Input() componentList: CarnetPage[];
  @Input() viewData: CarnetDocument;
  @Input() carnetFormState: CarnetFormState = 'carnetCreate';
  @Output() formSubmission = new EventEmitter<FormGroup>();

  private parentForm = new FormGroup({ });
  private modelId: string;
  public disableSubmit: boolean;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    if (this.carnetFormState === 'modelUpdate') {
      this.modelId = this.viewData.firestoreId;
    }
    
    this.viewData = this.viewData ? JSON.parse(this.viewData.carnet) : null;

    // Vu que le submitComponent recoit toujours le parentFrom en tant que NULLABLE_FORM_REF
    // la façon reguilière de lui passer un formControl ne fonctionnera pas. Le modelName
    // control est alors joint à parentForm pour que submitComponent puisse y acceder.
    if (this.carnetFormState === 'modelCreate' || this.carnetFormState === 'modelUpdate') {
      const modelNameControl = new FormControl(this.viewData?.modelName, Validators.required);
      this.parentForm.addControl('modelName', modelNameControl);
      modelNameControl.statusChanges
        .pipe(startWith(modelNameControl.status))
        .subscribe(val => this.disableSubmit = val === 'INVALID')
    } 
  }

  ngAfterViewInit(): void {

    this.viewContainerList.forEach((ref, i) => {
      const component: any = ref.createComponent(
        this.componentFactoryResolver.resolveComponentFactory(this.componentList[i].component),
        undefined,
        Injector.create({
          providers: [
            {
              provide: NULLABLE_FORM_REF, 
              useValue: !this.componentList[i].formName
                ? this.parentForm
                : this.getInjectionValue(this.viewData, i)
            },
            {
              provide: CARNET_FORM_STATE,
              useValue: this.carnetFormState,
            }
          ]
        })
      );
      if (component.instance.form) {
        this.parentForm.addControl(this.componentList[i].formName, component.instance.form);
      }
    });

    if (this.carnetFormState === 'carnetRead') {
      this.parentForm.disable();
    }

    this.changeDetectorRef.detectChanges();
  }

  getInjectionValue(viewData: any, listIndex: number) {
    return viewData ? viewData[this.componentList[listIndex].formName] : null;
  }

  forceFormValidation(form: FormGroup | FormArray): void {
    form.markAllAsTouched();
  }

  // Mieux ailleurs?
  onFormSubmission(): void {
    this.dialog
      .open(SubmitDialogComponent, {
        data: {
          form: this.parentForm,
          carnetFormState: this.carnetFormState,
          modelId: this.modelId,
        },
        disableClose: true,
      })
      .afterClosed()
      .pipe(filter(status => status === "SUCCESS"))
      .subscribe(() => this.router.navigateByUrl(
        this.carnetFormState.includes('carnetCreate') ? 'list' : 'modeles'
      ));
  }
}


