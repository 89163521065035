<div fxFlex="100" fxLayoutAlign="center start">
    <div class="base-card mb-16 mt-16">
        <div class="p-16" fxFlex="100">
            <h1>Contacter le comité SSE</h1>
            <ng-container *ngFor="let contact of contactInfo | keyvalue">
                <mat-card class="mb-16">
                    <mat-card-content>
                        {{ contact.value.text }}
                    </mat-card-content>
                    <mat-card-header>
                        <mat-card-title>
                            <a href="mailto:{{contact.value.email}}">
                                {{ contact.value.email }}
                            </a>
                        </mat-card-title>
                    </mat-card-header>
                </mat-card>
            </ng-container>
            <h1>Télécharger les documents SSE</h1>
            <mat-card>
                <mat-card-content>
                    <app-document-table></app-document-table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>