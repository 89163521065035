import { BaseAuthService } from '@alborea/portal';
import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormGroup } from '@angular/forms';
import { CarnetFormState } from '../global-tools';
import { CarnetDocument, carnetDocumentFromForm } from '../interfaces/carnet-document.interace';
import { ModelDocument, modelDocumentFromForm } from '../interfaces/model-document.interface';
import { FirestoreHelperService } from './firestore-helper.service';

@Injectable({ providedIn: 'root' })
export class CarnetDocumentService {

  constructor(
    private authService: BaseAuthService,
    private firestoreService: FirestoreHelperService,
  ) { }

  public getSubmitFunction(carnetFormState: CarnetFormState) {
    return this.submissionFunctionMap[carnetFormState].bind(this);
  }

  private submissionFunctionMap = {
    'carnetCreateFromModel': this.postCarnet,
    'carnetCreate': this.postCarnet,
    'modelCreate': this.postModel,
    'modelUpdate': this.updateModel,
  }

  // Les trois sont pas mal identiques
  private postCarnet(carnetForm: FormGroup): Promise<DocumentReference<CarnetDocument> | void> {
    const carnetDocument = carnetDocumentFromForm(carnetForm, this.authService.userInformation.user);
    const dbWritePromise = this.firestoreService
      .getProjectCollectionById(carnetDocument.projectId)
      .add(carnetDocument)
    // Evite promise non-resolu en mode hors-connexion
    return window.navigator.onLine ? dbWritePromise : Promise.resolve(); 
  }

  private postModel(modelForm: FormGroup): Promise<DocumentReference<ModelDocument> | void> {
    const dbWritePromise = this.firestoreService
      .getModelCollectionByUserId(this.authService.userInformation.user.id)
      .add(modelDocumentFromForm(modelForm))
    // Evite promise non-resolu en mode hors-connexion
    return window.navigator.onLine ? dbWritePromise : Promise.resolve();
  }

  private updateModel(modelForm: FormGroup, modelId: string): any {    
    const dbWritePromise = this.firestoreService
      .getModelCollectionByUserId(this.authService.userInformation.user.id)
      .doc(modelId)
      .update(modelDocumentFromForm(modelForm))
    return window.navigator.onLine ? dbWritePromise: Promise.resolve();
  }

}
