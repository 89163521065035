import { Component, Input, OnInit } from '@angular/core';
import { CarnetFormState } from 'src/app/global-tools';
import { CarnetDocument } from 'src/app/interfaces/carnet-document.interace';

@Component({
  selector: 'app-stepper-title',
  templateUrl: './stepper-title.component.html',
  styleUrls: ['./stepper-title.component.scss']
})
export class StepperTitleComponent implements OnInit {

  @Input() state: CarnetFormState;
  @Input() formRef: CarnetDocument;

  title: string;
  subtitle: string;

  ngOnInit(): void {
    switch (this.state) {
      case 'carnetCreate':
        this.title = 'Nouvelle analyse';
        break;
      case 'carnetCreateFromModel':
        this.title = 'Nouvelle analyse';
        this.subtitle = `À partir du modèle : ${this.formRef.modelName}`;
        break;
      case 'modelCreate':
        this.title = 'Nouveau modèle d\'analyse';
        break;
      case 'modelUpdate':
        this.title = 'Mise à jour de modèle';
        this.subtitle = `Nom du modèle : ${this.formRef.modelName}`;
        break;
      case 'carnetRead':
        this.title = 'Visualisation d\'analyse';
        break;
      default:
        throw new Error('No state received by stepper-title component.');
    }
  }

}
