<form [formGroup]="form">
    <div>
        <mat-form-field appearance="outline">
            <mat-label>Responsable Client</mat-label>
            <input matInput formControlName="contractorName"/>
            <mat-error *ngIf="carnetFormState.includes('carnetCreate')">
                Nom du responsable réquis.
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Téléphone</mat-label>
            <input type="tel" matInput formControlName="contractorTel"/>
            <mat-error *ngIf="carnetFormState.includes('carnetCreate')">
                Numéro de téléphone invalide.
            </mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="outline">
            <mat-label>Responsable Alphard</mat-label>
            <input matInput formControlName="alphardName"/>
            <mat-error *ngIf="carnetFormState.includes('carnetCreate')">
                Nom du responsable réquis.
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Téléphone</mat-label>
            <input type="tel" matInput formControlName="alphardTel"/>
            <mat-error *ngIf="carnetFormState.includes('carnetCreate')">
                Numéro de téléphone invalide.
            </mat-error>
        </mat-form-field>
    </div>
    <mat-list>
        <mat-list-item>
            <mat-slide-toggle formControlName="alphardTraining">
                Alphard - formation et accueil
            </mat-slide-toggle>
        </mat-list-item>
        <mat-list-item>
            <mat-slide-toggle formControlName="contractorTraining">
                Entrepreneur - formation et accueil
            </mat-slide-toggle>
        </mat-list-item>
    </mat-list>
</form>