import { FormGroup } from "@angular/forms";

export interface ModelDocument {
    name: string,
    lastRevisionDate: string,
    carnet: string;
    firestoreId?: string,
}

export const modelDocumentFromForm = (form: FormGroup): ModelDocument => {
    return {
        name: form.get('modelName').value,
        lastRevisionDate: new Date().toISOString(),
        carnet: JSON.stringify(form.value),
    }
}