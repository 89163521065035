import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CarnetFormState } from 'src/app/global-tools';

@Component({
  selector: 'app-stepper-buttons',
  templateUrl: './stepper-buttons.component.html',
  styleUrls: ['./stepper-buttons.component.scss']
})
export class StepperButtonsComponent  {
  
  @Input() first: boolean;
  @Input() last: boolean;
  @Input() disableSubmit: boolean;
  @Input() carnetFormState: CarnetFormState;
  @Output() formSubmission = new EventEmitter<void>();
  @Output() pageAdvance = new EventEmitter<void>();

  onSubmit(): void {
    this.formSubmission.emit();
  }

  onPageAdvance(): void {
    this.pageAdvance.emit();
  }
}
