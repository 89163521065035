export const navigation = [
    {
        'id'       : 'form',
        'title'    : 'Créer analyse de risque',
        'type'     : 'item',
        'icon'     : 'health_and_safety',
        'url'      : '/home'
    },
    {
        'id'       : 'list',
        'title'    : 'Mes analyses de risque',
        'type'     : 'item',
        'icon'     : 'line_style',
        'url'      : '/list'
    },
    {
        'id'       : 'list',
        'title'    : 'Mes modèles',
        'type'     : 'item',
        'icon'     : 'folder_open',
        'url'      : '/modeles'
    },
    {
        'id'       : 'contact',
        'title'    : 'Contact SSE',
        'type'     : 'item',
        'icon'     : 'email',
        'url'      : '/contacts'
    },
    // {
    //     'id'       : 'list',
    //     'title'    : 'Sync',
    //     'type'     : 'item',
    //     'icon'     : 'line_style',
    //     'url'      : '/sync'
    // },
];
