<div fxFlex>
    <mat-card>
        
        <form 
            [formGroup]="form"
            id="projectTaskForm"
            [albFormSaver]="form"
            (albFormSaverLoaded)="formLoaded($event)"
            fxLayout.gt-md="row"
            fxLayout.lt-md="column"
        >

            <div>
                <mat-form-field appearance="outline">
                    <mat-label>Chercher par projet</mat-label>
                    <mat-select
                        formControlName="project"
                        #projectSelect
                        [compareWith]="selectCompare"
                    >
                        <mat-option *ngFor="let project of projectList$ | async" [value]="project">
                            {{ project.code }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button
                    [disabled]="!projectSelect.value"
                    mat-raised-button
                    color="warn"
                    (click)="removeProjectFilter()">
                    <mat-icon>highlight_off</mat-icon>
                </button>
    
                
                <mat-form-field appearance="outline">
                    <mat-label>Chercher par lot</mat-label>
                    <mat-select
                        formControlName="task"
                        #taskSelect
                        [compareWith]="selectCompare"
                    >
                        <mat-option *ngFor="let task of taskList$ | async" [value]="task">
                            {{ task.code }} - {{ task.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button
                    [disabled]="!taskSelect.value"
                    mat-raised-button
                    color="warn"
                    (click)="removeTaskFilter()">
                    <mat-icon>highlight_off</mat-icon>
                </button>
            </div>


            <app-employee-picker
                *ngIf="SSE_MEMBER"
                [departmentControl]="form.controls.department"
                [authorControl]="form.controls.author"
            ></app-employee-picker>

         </form>

        <button
            (click)="handlePagination('dec')" 
            [disabled]="!paginationState.currentPage"
        >
            <mat-icon>chevron_left</mat-icon>
        </button>

        <button
            (click)="handlePagination('inc')" 
            [disabled]="!previousQueryResult.count || previousQueryResult.count % QUERY_LIMIT"
        >
            <mat-icon>chevron_right</mat-icon>
        </button>


        <div *ngIf="carnetList$ | async as data">

            <table mat-table [dataSource]="data" *ngIf="data.length else noCarnets">
                <ng-container matColumnDef="projectName">
                    <th mat-header-cell *matHeaderCellDef>Projet</th>
                    <td mat-cell *matCellDef="let document">
                        {{ document.projectCode }} 
                        <span fxHide.lt-md> - {{ document.projectName }}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="taskName">
                    <th mat-header-cell *matHeaderCellDef>Lot</th>
                    <td mat-cell *matCellDef="let document">
                        {{ document.taskCode }}
                        <span fxHide.lt-md> - {{ document.taskName }}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="submissionDate">
                    <th fxHide.lt-md mat-header-cell *matHeaderCellDef>Date de soumission</th>
                    <td fxHide.lt-md mat-cell *matCellDef="let document"> {{ document.submissionDate | date:'dd-MM-yyyy'}} </td>
                </ng-container>
    
                <ng-container matColumnDef="activityDate">
                    <th mat-header-cell *matHeaderCellDef>Date d'activité</th>
                    <td mat-cell *matCellDef="let document"> {{ document.activityDate | date:'dd-MM-yyyy'}} </td>
                </ng-container>
    
                <ng-container matColumnDef="authorName">
                    <th mat-header-cell *matHeaderCellDef>Soumis par</th>
                    <td mat-cell *matCellDef="let document"> {{ document.authorFirstName }} {{ document.authorLastName }} </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr 
                    mat-row
                    (click)="onCarnetSelection(row)"
                    *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>
    
            <ng-template #noCarnets>
                <p fxLayoutAlign="center center" style="margin-top: 20px;">
                    Aucun carnet disponible pour cette recherche.
                </p>
            </ng-template>
        </div>

    </mat-card>
</div>
