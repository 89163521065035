import { BaseAuthService, Project, ProjectService, Task, } from '@alborea/portal';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class ProjectHelperService {

  constructor(
    private projectService: ProjectService, 
    private authService: BaseAuthService
  ) {}

  private userId = this.authService.userInformation.user.id;

  public getProjectListObservable(): Observable<Project[]> {
    return this.projectService.myProjects();
  }

  public getTaskListObservable(projectId: number): Observable<Task[]> {
    return this.projectService.getProfileProjectTasks(projectId, this.userId);
  }
}
