import { Project, Task } from '@alborea/portal';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CarnetFormState, CARNET_FORM_STATE, NULLABLE_FORM_REF } from 'src/app/global-tools';
import { ProjectHelperService } from '../../services/project-helper.service';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {

  projectListObservable: Observable<Project[]>;
  taskListObservable: Observable<Task[]>;
  form: FormGroup;
  
  constructor(
    private fb: FormBuilder,
    private projectHelper: ProjectHelperService,
    @Inject(NULLABLE_FORM_REF) public formRef,
    @Inject(CARNET_FORM_STATE) public carnetFormState: CarnetFormState,
  ) {
    // Problèmes avec la validation si this.form n'est pas declaré dans le constructor ici - à revoir
    this.form = this.fb.group({
      project: [this.formRef?.project, Validators.required],
      task: [this.formRef?.task, Validators.required],
      date: [this.formRef?.date || new Date(), Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.carnetFormState !== 'carnetRead') {
      this.projectListObservable = this.projectHelper.getProjectListObservable();

      if (this.formRef?.project) {
        this.taskListObservable = this.projectHelper.getTaskListObservable(this.formRef.project.id)
      }

      this.form.get('project').valueChanges.subscribe(project => {
        this.taskListObservable = this.projectHelper.getTaskListObservable(project.id);
        this.form.get('task').reset();
      });
    }
  }

  selectCompare(a: Project | Task, b: Project | Task): boolean {
    return a?.id === b?.id; 
  }

}
