<table mat-table [dataSource]="documentList$ | async">
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Document</th>
        <td mat-cell *matCellDef="let document">
            {{ document.description }} 
        </td>
    </ng-container>

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Dernière mise à jour</th>
        <td mat-cell *matCellDef="let document">
            {{ document.lastUpdatedDate | date: 'dd/MM/yy' }} 
        </td>
    </ng-container>

    <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef>Télécharger</th>
        <td mat-cell *matCellDef="let document">
            <button
                mat-raised-button
                color="accent"
                (click)="download(document)"
            >
                <mat-icon>save_alt</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr 
        mat-header-row 
        *matHeaderRowDef="displayedColumns"
    ></tr>
    <tr 
        mat-row
        *matRowDef="let row; columns: displayedColumns;"
    ></tr>
</table>