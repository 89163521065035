<form [formGroup]="form">
    <div>
        <app-info-button
            *ngIf="!form.controls.length && carnetFormState.includes('carnetCreate')"
            type="warn"
            text="Veuillez ajouter une activité"
            (click)="addActivity()"
        ></app-info-button>

        <app-info-button
            *ngIf="carnetFormState !== 'carnetRead'"
            type="add"
            text="Ajouter une activité"
            (click)="addActivity()"
        ></app-info-button>

        <div 
            *ngFor="let control of form.controls; index as i"
            fxLayoutAlign="start center"
        >
            <mat-form-field fxFlex="80">
                <mat-label>
                    Activité {{i + 1}}
                </mat-label>
                <textarea 
                    fxFlex
                    matInput
                    [value]="control.value"
                    [formControlName]="i"
                ></textarea>
                <mat-error *ngIf="control.touched && (carnetFormState === 'carnetCreate' || carnetFormState === 'carnetCreateFromModel')">
                    Activité vide!
                </mat-error>
            </mat-form-field>
            <button
                *ngIf="carnetFormState !== 'carnetRead'" 
                mat-icon-button
                color="warn" 
                (click)="deleteActivity(i)"
            >
                <mat-icon>
                    delete
                </mat-icon>
            </button> 
        </div>
    </div>
</form>
