<table 
    mat-table
    *ngIf="modelList && modelList.length"
    [dataSource]="modelList"
>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nom</th>
        <td mat-cell *matCellDef="let model">
            {{ model.name }}
        </td>
    </ng-container>

    <ng-container
        matColumnDef="lastRevisionDate"
    >
        <th mat-header-cell *matHeaderCellDef fxHide.lt-md>Date de révision</th>
        <td mat-cell *matCellDef="let model" fxHide.lt-md>
            {{ model.lastRevisionDate | date:'dd-MM-yyyy' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>Modifier</th>
        <td mat-cell *matCellDef="let model">
            <button 
                mat-raised-button 
                color="accent"
                (click)="modelEdit(model)"
            >
                <mat-icon>edit</mat-icon>
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="createFrom">
        <th mat-header-cell *matHeaderCellDef>Créer carnet</th>
        <td 
            mat-cell 
            *matCellDef="let model"
        >
            <button 
                mat-raised-button
                color="accent"
                (click)="createFromModel(model)"
            >
                <mat-icon>post_add</mat-icon>
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Supprimer</th>
        <td 
            mat-cell 
            *matCellDef="let model"
        >
            <button 
                mat-raised-button 
                color="warn"
                (click)="onModelDelete(model)"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr 
        mat-header-row 
        *matHeaderRowDef="displayedColumns"
    ></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
    ></tr>

</table>

<div *ngIf="modelList && !modelList.length">
    <p fxLayoutAlign="center center">
        Vos modèles s'afficheront ici - cliquer sur Créer un modèle pour commencer.
    </p>
</div>
