<form [formGroup]="form">
    <mat-hint 
        class="mat-error" 
        *ngIf="form.touched && form.invalid && carnetFormState.includes('carnetCreate')"
    >
        Veuillez saisir au moins 1 danger potentiel
    </mat-hint>
    <div *ngFor="let control of form.controls | keyvalue: nullSort">
        <div class="with-button">
            <mat-checkbox [formControlName]="control.key">
                {{ control.key }}
            </mat-checkbox>
        </div>
    </div>
    <ng-container *ngIf="carnetFormState !== 'carnetRead'">
        <mat-form-field class="with-button">
            <input
                [(ngModel)]="newDanger"
                [ngModelOptions]="{ standalone: true }"
                matInput 
                placeholder="Autre"
            />
        </mat-form-field>
        <button
            [disabled]="!newDanger"
            mat-icon-button color="accent" 
            (click)="addDanger()">
            <mat-icon>add_box</mat-icon>
        </button> 
    </ng-container>
</form>