import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CarnetFormState, CARNET_FORM_STATE, NotAllValidator, NULLABLE_FORM_REF } from 'src/app/global-tools';
import { equipmentList } from './equipement';

@Component({
  selector: 'app-ppe',
  templateUrl: './ppe.component.html',
  styleUrls: ['./ppe.component.scss']
})
export class PPEComponent {

  constructor(
    private fb: FormBuilder,
    @Inject(NULLABLE_FORM_REF) public formRead,
    @Inject(CARNET_FORM_STATE) public carnetFormState: CarnetFormState,
  ) {}

  form = this.fb.group(
    this.formRead
      ? {...this.formRead}
      : equipmentList.reduce((obj, val) => {
          obj[val] = false;
          return obj;
        }, {}),
    { validators: NotAllValidator(false) }
  );
}
