import { EnterGuard } from "@alborea/portal";
import { FormBaseComponent } from "../form/form-base/form-base.component";
import { CarnetListComponent } from "../carnet-list/carnet-list.component";
import { SystemSyncComponent } from "../system-sync/system-sync.component";
import { SSEContactComponent } from "../sseinfo/ssecontact/ssecontact.component";
import { ModelHomeComponent } from "../models/model-home/model-home.component";

export const routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
    canActivate: [EnterGuard],
  },
  {
    path: 'home',
    pathMatch: 'full',
    component: FormBaseComponent,
    canActivate: [EnterGuard],
  },
  {
    path: 'list',
    pathMatch: 'full',
    component: CarnetListComponent,
    canActivate: [EnterGuard],
  },
  {
    path: 'sync',
    pathMatch: 'full',
    component: SystemSyncComponent,
    canActivate: [EnterGuard],
  },
  {
    path: 'visualiser',
    component: FormBaseComponent,
    canActivate: [EnterGuard],
  },
  {
    path: 'contacts',
    component: SSEContactComponent,
    canActivte: [EnterGuard],
  },
  {
    path: 'modeles',
    component: ModelHomeComponent,
    canActivte: [EnterGuard],
  },
];
