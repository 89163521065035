import { Component, OnInit } from '@angular/core';
import { ActivitiesComponent } from 'src/app/carnet-pages/activities/activities.component';
import { ContactComponent } from 'src/app/carnet-pages/contact/contact.component';
import { DangerComponent } from 'src/app/carnet-pages/danger/danger.component';
import { InformationComponent } from 'src/app/carnet-pages/information/information.component';
import { RisksComponent } from 'src/app/carnet-pages/risks/risks.component';
import { SubmitComponent } from 'src/app/carnet-pages/submit/submit.component';
import { PPEComponent } from 'src/app/carnet-pages/ppe/ppe.component';
import { CarnetFormState, CarnetPage } from 'src/app/global-tools';
import { CarnetDocument } from 'src/app/interfaces/carnet-document.interace';
import { Router } from '@angular/router';
import { ModelDocument } from 'src/app/interfaces/model-document.interface';

@Component({
  selector: 'app-form-base',
  templateUrl: './form-base.component.html',
  styleUrls: ['./form-base.component.scss']
})
export class FormBaseComponent implements OnInit {
  
  constructor(private router: Router) { }

  nullableCarnetReference: CarnetDocument | ModelDocument = this.router.getCurrentNavigation().extras?.state?.carnetRef;
  carnetFormState: CarnetFormState = this.router.getCurrentNavigation().extras?.state?.carnetFormState;

  public pages: CarnetPage[] = [
    {title: 'Information sur les travaux', formName: 'information', component: InformationComponent},
    {title: 'Activités à réaliser', formName: 'activities', component: ActivitiesComponent},
    // {title: 'Dangers anticipés ou potentiels', formName: 'danger', component: DangerComponent},
    {title: 'Équipement de protection individuelle', formName: 'equipment', component: PPEComponent},
    {title: 'Gestion du risque', formName: 'risk', component: RisksComponent},
    {title: 'Formation et accueil', formName: 'contact', component: ContactComponent},
  ];

  ngOnInit(): void {
    if (this.carnetFormState !== 'carnetRead') {
      this.pages.push({ title: 'Soumission', component: SubmitComponent });
    }
  }

}
