export const equipmentList = [
    'Protection de pieds',
    'Protection de la tête',
    'Visibilité',
    'Protection des yeux',
    'Protection auditive',
    'Protection respiratoire',
    'Protection des mains',
    'Protection des agressions extérieures',
    'Protection de chute',
];