import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CarnetFormState } from 'src/app/global-tools';
import { CarnetDocumentService } from 'src/app/services/carnet-document.service';

@Component({
  selector: 'app-submit-dialog',
  templateUrl: './submit-dialog.component.html',
  styleUrls: ['./submit-dialog.component.scss']
})
export class SubmitDialogComponent implements OnInit {

  constructor(
    private carnetDocumentService: CarnetDocumentService,
    @Inject(MAT_DIALOG_DATA) public data: {form: FormGroup, carnetFormState: CarnetFormState, modelId: string}
  ) { }

  promiseStatus: string = 'PENDING';

  ngOnInit(): void {
    const submitFn = this.carnetDocumentService.getSubmitFunction(this.data.carnetFormState);
    submitFn(this.data.form, this.data.modelId)
      .then(() => this.promiseStatus = 'SUCCESS')
      .catch(() => this.promiseStatus = 'FAILURE')
  }
}
