import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CarnetFormState, CARNET_FORM_STATE, NULLABLE_FORM_REF } from 'src/app/global-tools';
import { RiskMatrixDialogComponent } from '../risk-matrix-dialog/risk-matrix-dialog.component';
import { dangerOptions, probabilityOptions, riskMatrix, severityOptions } from './risks.options';
import { TaskFormValues, DangerFormValues } from './risks.interface';

@Component({
  selector: 'app-risks',
  templateUrl: './risks.component.html',
  styleUrls: ['./risks.component.scss']
})
export class RisksComponent {

  dangerOptions = dangerOptions;
  severityOptions = severityOptions;
  probabilityOptions = probabilityOptions;

  readonly generateTaskGroup = (taskForm?: TaskFormValues) => this.fb.group({
    description: this.fb.control(taskForm?.description, Validators.required),
    dangers: this.fb.array(
      taskForm?.dangers
        ? taskForm.dangers.map(danger => this.generateDangerGroup(danger))
        : [this.generateDangerGroup()],
      Validators.required
    ),
  })

  readonly generateDangerGroup = (dangerForm?: DangerFormValues) => this.fb.group({
    danger: this.fb.control(dangerForm?.danger, Validators.required),
    severity: this.fb.control(dangerForm?.severity, Validators.required),
    probability: this.fb.control(dangerForm?.probability, Validators.required),
    prevention: this.fb.control(dangerForm?.prevention, Validators.required),
    level: this.fb.control(dangerForm?.level, Validators.required),
  })

  constructor (
    private fb: FormBuilder,
    private matDialog: MatDialog,
    @Inject(NULLABLE_FORM_REF) public formRef: TaskFormValues[] | null,
    @Inject(CARNET_FORM_STATE) public carnetFormState: CarnetFormState,
  ) { }

  form = this.fb.array(
    this.formRef
      ? this.formRef.map(taskValues => this.generateTaskGroup(taskValues))
      : [this.generateTaskGroup()],
    [Validators.required]
  );

  addTask(): void {
    this.form.push(this.generateTaskGroup());
  }

  deleteTask(i: number): void {
    this.form.removeAt(i);
  }

  addDanger(taskForm: FormGroup): void {
    (taskForm.get('dangers') as FormArray).push(this.generateDangerGroup());
  }

  deleteDanger(taskForm: FormGroup, index: number): void {
    (taskForm.get('dangers') as FormArray).removeAt(index);
  }

  toggleDialog(): void {
    this.matDialog.open(RiskMatrixDialogComponent);
  }

  calculateRiskLevel(dangerForm: FormGroup): void {
    const { severity, probability } = dangerForm.value as DangerFormValues; 
    if (!severity || !probability) {
      return;
    }
    const score = riskMatrix[severity] + riskMatrix[probability];
    let letter = 'C';
    if (score > 6) {
      letter = 'A';
    }
    if (score === 6) {
      letter = 'B'
    };
    dangerForm.patchValue({ level: letter });
  }

}
