import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarnetListComponent } from './carnet-list.component';
import { MaterialModule } from '../material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StepperModule } from '../stepper/stepper.module';
import { FormModule } from '../form/form.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormSaverModule } from '@alborea/common';
import { EmployeePickerComponent } from './employee-picker/employee-picker.component';

@NgModule({
  declarations: [
    CarnetListComponent,
    EmployeePickerComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    StepperModule,
    FormModule,
    ReactiveFormsModule,
    FormSaverModule,
  ], 
  exports: [
    CarnetListComponent,
  ]
})

export class CarnetListModule { }
