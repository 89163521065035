export type SSEContact = {
    text: string,
    email: string,
    telephone?: string,
}

export type SSEContactGroup = {
    [contactName: string]: SSEContact,
}

export const contactInfo: SSEContactGroup = {
    accident: {
        text: `
            Pour rapporter un accident, un incident ou un
            quasi-accident au comité SSE de Groupe Alphard,
            envoyer un courriel à : 
        `,
        email: 'accident@alphard.com',
    },
    general: {
        text: `
            Pour toute autre question non-urgente concernante
            le SSE et l'analyse de risque, veuillez contacter : 
        `,
        email: 'sse@alphard.com',
    }
}