import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor(private snackBar: MatSnackBar) { }

  // Add "ERROR" param - if ERROR true, change snack bar classes.
  openSnackBar(message: string, duration?: number) {
    this.snackBar.open(message, 'X', {duration: duration || 2000});
  }

}
