import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-model-home',
  templateUrl: './model-home.component.html',
  styleUrls: ['./model-home.component.scss']
})
export class ModelHomeComponent {

  constructor(private router: Router) { }

  createModel(): void {
    this.router.navigate(['visualiser'], { 
      state: { 
        carnetFormState: 'modelCreate' 
      } 
    });
  }
  
}
