import { Profile } from "@alborea/portal";
import { FormGroup } from "@angular/forms";

export interface CarnetDocument {
    projectName: string;
    projectCode: string;
    projectId: number;
    taskName: string;
    taskCode: string;
    taskId: number;
    authorFirstName: string;
    authorLastName: string;
    authorId: number;
    submissionDate: string;
    activityDate: string;
    activityDateTimestamp: number;
    carnet: string;
    modelName?: string;
    firestoreId?: string;
}

export const carnetDocumentFromForm = (form: FormGroup, profile: Profile): CarnetDocument => {
    const informationForm = form.value.information;
    
    // Quand un carnet est crée à partir d'un modèle, informationForm.date est un string
    // Ceci peut faire le contraire alors - convertir un object en string pour activityDate / activityDateTimestamp
    if (typeof informationForm.date === 'string') {
        informationForm.date = new Date(informationForm.date);
    }

    return {
        projectName: informationForm.project.shortName,
        projectCode: informationForm.project.code,
        projectId: informationForm.project.id,
        taskName: informationForm.task.name,
        taskCode: informationForm.task.code,
        taskId: informationForm.task.id,
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        authorId: profile.id,
        submissionDate: new Date().toISOString(),
        activityDate: informationForm.date.toISOString(),
        activityDateTimestamp: Date.parse(informationForm.date),
        carnet: JSON.stringify(form.value),
    }
}