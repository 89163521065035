import { toQueryParams } from '@alborea/loopback';
import { Department } from '@alborea/portal';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as config from '../../assets/config/config.json';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  private readonly deparmentRoot = `${config.portalURL}/departments`;

  constructor(private http: HttpClient) { }

  getDepartmentList(): Observable<Department[]> {
    return this.http.get<Department[]>(
      `${this.deparmentRoot}${toQueryParams('filter', {include: ['profiles']})}`
    );
  }

}
