import { BaseAuthService } from '@alborea/portal';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModelDocument } from 'src/app/interfaces/model-document.interface';
import { FirestoreHelperService } from 'src/app/services/firestore-helper.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'app-model-list',
  templateUrl: './model-list.component.html',
  styleUrls: ['./model-list.component.scss']
})
export class ModelListComponent {

  constructor(
    private firestoreService: FirestoreHelperService,
    private authService: BaseAuthService,
    private router: Router,
    private tools: ToolsService,
  ) { }

  displayedColumns = [ 'name' , 'lastRevisionDate', 'edit', 'createFrom', 'delete' ];

  modelList: ModelDocument[];
  modelListSubscription = this.firestoreService
    .getModelCollectionByUserId(this.authService.userInformation.user.id)
    .valueChanges({idField: 'firestoreId'})
    .subscribe(res => this.modelList = res);

  // modelEdit and createFromModel pas mal identique
  modelEdit(model: ModelDocument): void {
    this.router.navigate(
      ['visualiser'],
      {
        state: {
          carnetRef: model,
          carnetFormState: 'modelUpdate',
      }
    });
  }

  createFromModel(model: ModelDocument): void {
    this.router.navigate(
      ['visualiser'], 
      {
        state: {
          carnetRef: model,
          carnetFormState: 'carnetCreateFromModel',
      }
    });
  }

  onModelDelete(model: ModelDocument): void {
    this.firestoreService
      .getModelCollectionByUserId(this.authService.userInformation.user.id)
      .doc(model.firestoreId)
      .delete()
      .then(() => this.tools.openSnackBar('Modèle supprimé'))
      .catch(() => this.tools.openSnackBar('Un problème est surevenu. Veuillez réssayer', 5000))
  }
}
