import { InjectionToken } from "@angular/core";
import { ValidatorFn, FormGroup } from "@angular/forms";

export const NULLABLE_FORM_REF = new InjectionToken<any>('nullableFormRef');
export const CARNET_FORM_STATE = new InjectionToken<CarnetFormState>('carnetFormState');

export type CarnetFormState = 
  'carnetCreate'
  | 'carnetRead'
  | 'carnetCreateFromModel'
  | 'modelCreate'
  | 'modelUpdate';

export interface CarnetPage {
  title: string,
  component: any,
  formName?: string,
}

// Valide qu'il existe au moins une contrôle qui n'est pas égal aux valeurs fournies dans @args
export const NotAllValidator = (...args: any[]): ValidatorFn => {
  return (form: FormGroup): {[key: string]: any} | null => {
    const formValues = Object.values(form.value);
    return !formValues.some(e => !args.includes(e))
      ? {error: `Fails not all (${args})`}
      : null;
  }
}

