import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FuseModule} from '@alborea/fuse';
import { TOOLBAR_NAVIGATION } from '@alborea/fuse-extra';
import { RouterModule } from '@angular/router';
import { SubSiteModule, PortalAppModule, AppComponent, SharedAuthModule} from '@alborea/portal';
import { HttpClientModule } from '@angular/common/http';
import { routes } from './config/routes';
import { fuseConfig } from './config/fuse-config';
import { navigation } from './config/navigation';
import { FormModule } from './form/form.module';
import { MatNativeDateModule } from '@angular/material/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { SystemSyncComponent } from './system-sync/system-sync.component';
import { CarnetListModule } from './carnet-list/carnet-list.module';

import {EnvConfigModule} from '@alborea/common';
import { SSEInfoModule } from './sseinfo/sseinfo.module';
import { ModelsModule } from './models/models.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    SharedAuthModule.forRoot(),
    EnvConfigModule.forRoot(),
    SubSiteModule.forRoot(),
    RouterModule.forRoot(routes),
    FuseModule.forRoot(fuseConfig),
    PortalAppModule,
    FormModule,
    CarnetListModule,
    SSEInfoModule,
    ModelsModule,
    MatNativeDateModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence(),
  ],
  providers: [
    {
      provide: TOOLBAR_NAVIGATION,
      useValue: navigation,
    },
  ],
  bootstrap: [AppComponent],
  declarations: [SystemSyncComponent]
})
export class AppModule { }
