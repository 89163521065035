import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperBaseComponent } from './stepper-base/stepper-base.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../material/material.module';
import { ScrollerDirective } from './scroller.directive';
import { StepperButtonsComponent } from './stepper-buttons/stepper-buttons.component';
import { SubmitDialogComponent } from './submit-dialog/submit-dialog.component';
import { StepperTitleComponent } from './stepper-title/stepper-title.component';

@NgModule({
  declarations: [
    StepperBaseComponent, 
    ScrollerDirective, 
    StepperButtonsComponent,
    SubmitDialogComponent,
    StepperTitleComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
  ],
  exports: [
    StepperBaseComponent,
  ]
})
export class StepperModule { }
