import { Component } from '@angular/core';
import { SSEDocument, SSEDocumentService } from '../ssedocument.service';

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html',
  styleUrls: ['./document-table.component.scss']
})
export class DocumentTableComponent {

  constructor(private sseDocumentService: SSEDocumentService) { }

  documentList$ = this.sseDocumentService.getDocumentList();

  displayedColumns = [ 'description', 'date', 'download' ];

  download(document: SSEDocument): void {
    this.sseDocumentService.downloadDocument(document);
  }

}
