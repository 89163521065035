// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  projectsCollectionRoot: 'projects',
  projectsCollectionEnd: 'carnets',
  tasksCollectionRoot: 'tasks',
  authorsCollectionRoot: 'authors',
  userCollectionRoot: 'users',
  userCollectionEnd: 'models',
  firebaseConfig: {
    apiKey: "AIzaSyAMhNebpl6Ao8Kr2qfbtF4XnMin-m6REeQ",
    authDomain: "carnet-ec26b.firebaseapp.com",
    projectId: "carnet-ec26b",
    storageBucket: "carnet-ec26b.appspot.com",
    messagingSenderId: "117984450302",
    appId: "1:117984450302:web:314be50b5f1584218ab7af"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
