<app-info-button
    *ngIf="carnetFormState !== 'carnetRead'"
    buttonClass="accent"
    icon="grid_on"
    text='Visualiser la matrice de risque'
    (click)="toggleDialog()"
></app-info-button>

<app-info-button
    *ngIf="!form.controls.length && carnetFormState.includes('carnetCreate')"
    type="warn"
    text="Veuillez ajouter une tâche"
    (click)="addTask()"
></app-info-button>

<mat-card *ngFor="let taskGroup of form.controls; index as i" fxLayout="column">
    <div fxLayout="row" class="title">
        <h2 fxFlex>
            Tâche {{ i + 1 }}
        </h2>
        <button
            *ngIf="carnetFormState !== 'carnetRead'"
            mat-icon-button
            color="warn"
            (click)="deleteTask(i)">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
    <form [formGroup]="taskGroup">

        <mat-form-field appearence="outline">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" />
            <mat-error *ngIf="carnetFormState.includes('carnetCreate')">
                Description vide!
            </mat-error>
        </mat-form-field>

        <app-info-button
            *ngIf="
                !taskGroup.get('dangers').controls.length &&
                carnetFormState.includes('carnetCreate')
            "
            type="warn"
            text="Veuillez ajouter un danger"
            (click)="addDanger(taskGroup)"

        ></app-info-button>
        
        <div *ngFor="let dangerGroup of taskGroup.get('dangers').controls; index as j">

            <form [formGroup]="dangerGroup">

                <div fxLayout.lt-md="column">
                    <mat-form-field>
                        <mat-label>Danger anticipé</mat-label>
                        <mat-select formControlName="danger">
                            <mat-option 
                                *ngFor="let value of dangerOptions" 
                                [value]="value"
                            >
                                {{ value }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="carnetFormState.includes('carnetCreate')">
                            Danger vide!
                        </mat-error>
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label>Mesures préventative</mat-label>
                        <input matInput formControlName="prevention" />
                        <mat-error *ngIf="carnetFormState.includes('carnetCreate')">
                            Prévention vide!
                        </mat-error>
                    </mat-form-field>

                    
                    <button
                        *ngIf="carnetFormState !== 'carnetRead'"
                        mat-icon-button
                        color="warn"
                        (click)="deleteDanger(taskGroup, j)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>

                <div fxLayout.lt-md="column">
                    <mat-form-field fxFlex="45">
                        <mat-label>Gravité</mat-label>
                        <mat-select 
                            formControlName="severity"
                            (selectionChange)="calculateRiskLevel(dangerGroup)"
                        >
                            <mat-option 
                                *ngFor="let value of severityOptions" 
                                [value]="value"
                            >
                                {{ value }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="carnetFormState.includes('carnetCreate')">
                            Gravité vide!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="45">
                        <mat-label>Probabilité</mat-label>
                        <mat-select
                            formControlName="probability"
                            (selectionChange)="calculateRiskLevel(dangerGroup)"
                        >
                            <mat-option 
                                *ngFor="let value of probabilityOptions" 
                                [value]="value"
                            >
                                {{ value }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="carnetFormState.includes('carnetCreate')">
                            Probabilité vide!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="10">
                        <mat-label>Cotation</mat-label>
                        <input 
                            matInput
                            disabled
                            [value]="dangerGroup.value.level"
                        />
                    </mat-form-field>
                </div>
            </form>
        </div>
        <app-info-button
            *ngIf="carnetFormState !== 'carnetRead'"
            type="add"
            text="Ajouter un danger"
            (click)="addDanger(taskGroup)"
        ></app-info-button>
    </form>
</mat-card>

<app-info-button
    *ngIf="carnetFormState !== 'carnetRead'"
    type="add"
    text="Ajouter une tâche"
    (click)="addTask()"
></app-info-button>
