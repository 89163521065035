<mat-form-field appearance="outline">
    <mat-label>Departement</mat-label>
    <mat-select
        #departmentSelect
        [compareWith]="selectCompare"
        [formControl]="departmentControl"
    >
        <mat-option *ngFor="let department of deparmentList$ | async" [value]="department">
            {{ department.title }}
        </mat-option>
</mat-select>
</mat-form-field>
<button
    [disabled]="!departmentSelect.value"
    mat-raised-button
    color="warn"
    (click)="removeDeparmentFilter()">
    <mat-icon>highlight_off</mat-icon>
</button>

<mat-form-field appearance="outline">
<mat-label>Employee</mat-label>
<mat-select
    #authorSelect
    [formControl]="authorControl"
    [compareWith]="selectCompare"
>
    <mat-option *ngFor="let profile of profileList$ | async" [value]="profile">
        {{ profile.firstName }} {{ profile.lastName }}
    </mat-option>
</mat-select>
</mat-form-field>
<button
    [disabled]="!authorSelect.value"
    mat-raised-button
    color="warn"
    (click)="removeAuthorFilter()">
    <mat-icon>highlight_off</mat-icon>
</button>
