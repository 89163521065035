import { BaseAuthService } from '@alborea/portal';
import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CarnetFormState, CARNET_FORM_STATE, NULLABLE_FORM_REF } from 'src/app/global-tools';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  telephoneRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  
  constructor(
    private fb: FormBuilder,
    private authService: BaseAuthService,
    @Inject(NULLABLE_FORM_REF) public formRef,
    @Inject(CARNET_FORM_STATE) public carnetFormState: CarnetFormState,
  ) {}

  user = this.authService.userInformation.user; 

  form = this.fb.group({
     alphardName: [this.formRef?.alphardName || `${this.user.firstName} ${this.user.lastName}`, Validators.required],
     alphardTel: [this.formRef?.alphardTel, [Validators.required, Validators.pattern(this.telephoneRegEx)]],
     contractorName: [this.formRef?.contractorName, Validators.required],
     contractorTel: [this.formRef?.contractorTel, [Validators.required, Validators.pattern(this.telephoneRegEx)]],
     alphardTraining: this.formRef?.alphardTraining,
     contractorTraining: this.formRef?.contractorTraining,
  });
}
