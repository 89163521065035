import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationComponent } from './information/information.component';
import { DangerComponent } from './danger/danger.component';
import { MaterialModule } from '../material/material.module';
import { PPEComponent } from './ppe/ppe.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ActivitiesComponent } from './activities/activities.component';
import { RisksComponent } from './risks/risks.component';
import { SubmitComponent } from './submit/submit.component';
import { ContactComponent } from './contact/contact.component';
import { ProjectHelperService } from '../services/project-helper.service';
import { RiskMatrixDialogComponent } from './risk-matrix-dialog/risk-matrix-dialog.component';
import { InfoButtonComponent } from './info-button/info-button.component';

@NgModule({
  declarations: [
    InformationComponent,
    DangerComponent,
    PPEComponent,
    ActivitiesComponent,
    RisksComponent,
    SubmitComponent,
    ContactComponent,
    RiskMatrixDialogComponent,
    InfoButtonComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    InformationComponent,
    DangerComponent,
    PPEComponent,
    ActivitiesComponent,
    RisksComponent,
    SubmitComponent,
  ],
  providers: [
    ProjectHelperService,
  ]
})
export class CarnetPagesModule { }
