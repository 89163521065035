import { Injectable } from "@angular/core";
import { ProjectHelperService } from "./project-helper.service";

@Injectable({providedIn: 'root'})
export class SystemSyncService {

    constructor(private projectHelper: ProjectHelperService) {}

    // implementer take one here to avoid unsubscriptions
    syncRequests(): void {
        const projectStream = this.projectHelper.getProjectListObservable();
        projectStream.subscribe(p => {
            p.forEach(element => {
                this.projectHelper.getTaskListObservable(element.id).subscribe(t => console.log(t));
            });
        })
    }

}