<form [formGroup]="form">
    <mat-list>
        <app-info-button
            *ngIf="form.touched && form.invalid && carnetFormState.includes('carnetCreate')"
            type="warn"
            text="Veuillez saisir une article d'EPI"
        ></app-info-button>
        <mat-list-item *ngFor="let control of form.controls | keyvalue">
            <mat-slide-toggle [formControlName]="control.key">
                {{control.key}}
            </mat-slide-toggle>
        </mat-list-item>
    </mat-list>
</form>
