export const dangers: string[] = [
    'Électrique',
    'Mécanique',
    'Chimique',
    'Thermique',
    'Pression',
    'Émanation',
    'Énergie',
    'Gravité',
    'Travaux en hauteur',
    'Risque de chute',
    'Travaux en espace clos',
    'Indendie, explosion',
    'Environnement',
    'Coupure',
    'Coactivité',
    'Ergonomie',
    'Bruit'
  ]