import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { startWith } from 'rxjs/operators';
import { CarnetFormState, CARNET_FORM_STATE, NULLABLE_FORM_REF } from 'src/app/global-tools';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class SubmitComponent {

  // Ici le NULLABLE_FORM_REF n'est pas nullable - submitComponent reçoit toujours le parentForm de stepper-base
  constructor(
    @Inject(NULLABLE_FORM_REF) private formRef : FormGroup,
    @Inject(CARNET_FORM_STATE) public carnetFormState: CarnetFormState
  ) { }

  informationValue$ = this.formRef.get('information').valueChanges
    .pipe(startWith(this.formRef.value.information));
  
  activitiesValue$ = this.formRef.get('activities').valueChanges
    .pipe(startWith(this.formRef.value.activities));

}
