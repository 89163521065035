import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CarnetFormState, CARNET_FORM_STATE, NULLABLE_FORM_REF } from 'src/app/global-tools';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent {

  readonly generateDefaultControl = (value?: string) => this.fb.control(value, [Validators.required]);

  constructor(
    private fb: FormBuilder,
    @Inject(NULLABLE_FORM_REF) public formRef: string[] | null,
    @Inject(CARNET_FORM_STATE) public carnetFormState: CarnetFormState
  ) { }

  form = this.fb.array(
    this.formRef 
      ? this.formRef.map(val => this.generateDefaultControl(val)) 
      : [this.generateDefaultControl()],
    [Validators.required],
  );

  addActivity(): void {
    this.form.push(this.generateDefaultControl());
  }

  deleteActivity(i: number): void {
    this.form.removeAt(i);
  }
}
