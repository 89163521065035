import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CarnetFormState, CARNET_FORM_STATE, NotAllValidator, NULLABLE_FORM_REF } from 'src/app/global-tools';
import { dangers } from './dangers';

@Component({
  selector: 'app-danger',
  templateUrl: './danger.component.html',
  styleUrls: ['./danger.component.scss']
})
export class DangerComponent implements OnInit {

  form = this.fb.group({}, {validators: NotAllValidator(false)});
  newDanger: string;

  constructor(
    private fb: FormBuilder,
    @Inject(NULLABLE_FORM_REF) public formRef,
    @Inject(CARNET_FORM_STATE) public carnetFormState: CarnetFormState,
  ) { }

  ngOnInit(): void {
    this.formRef 
      ? Object.entries(this.formRef).forEach(([k,v]) => this.form.addControl(k, this.fb.control(v)))
      : dangers.forEach(danger => this.form.addControl(danger, this.fb.control(false)));
      
    // Peupler le form en ngOnInit cause la réactivation du formulaire ; désactiver le formulaire si en mode read  
    this.carnetFormState === 'carnetRead' && this.form.disable();
  }

  addDanger(): void {
    this.form.addControl(this.newDanger, this.fb.control(true));
    this.newDanger = '';
  }

  nullSort(): number {
    return 0;
  }
}
