import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBaseComponent } from './form-base/form-base.component';
import { CarnetPagesModule } from '../carnet-pages/carnet-pages.module';
import { StepperModule } from '../stepper/stepper.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [FormBaseComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    CarnetPagesModule,
    StepperModule,
  ],
  exports: [FormBaseComponent]

})
export class FormModule { }
