import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelHomeComponent } from './model-home/model-home.component';
import { ModelListComponent } from './model-list/model-list.component';
import { MaterialModule } from '../material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    ModelHomeComponent,
    ModelListComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
  ],
  exports: [
    ModelHomeComponent
  ]
})
export class ModelsModule { }
