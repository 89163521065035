<mat-card fxFlex.lt-md="100">
    <app-stepper-title
        [state]="carnetFormState"
        [formRef]="viewData"
    ></app-stepper-title>
    <mat-card-content>
        <mat-vertical-stepper
            appScroller
            [linear]="carnetFormState.includes('carnetCreate')"
        >
            <mat-step 
                *ngFor="let component of componentList; first as isFirst; last as isLast" 
                [stepControl]="parentForm.get(component.formName)">
                <ng-template matStepLabel>{{ component.title }}</ng-template>
                <ng-template #componentOutlet></ng-template>
                <app-stepper-buttons
                    [first]="isFirst"
                    [last]="isLast"
                    [disableSubmit]="disableSubmit"
                    [carnetFormState]="carnetFormState" 
                    (formSubmission)="onFormSubmission()"
                    (pageAdvance)="forceFormValidation(parentForm.get(component.formName))"
                ></app-stepper-buttons>
            </mat-step>
        </mat-vertical-stepper>
    </mat-card-content>
</mat-card>

 