import { ProbabilityOption, RiskMatrix, SeverityOption } from "./risks.interface";

export const dangerOptions: string[] = [
  'Électrique',
  'Mécanique',
  'Chimique',
  'Thermique',
  'Pression',
  'Émanation',
  'Énergie',
  'Gravité',
  'Travaux en hauteur',
  'Risque de chute',
  'Travaux en espace clos',
  'Indendie, explosion',
  'Environnement',
  'Coupure',
  'Coactivité',
  'Ergonomie',
  'Bruit'
];

export const severityOptions: SeverityOption[] = [
  'Mortalité',
  'Grave',
  'Moyennement grave',
  'Mineure',
  'Négligable'
];

export const probabilityOptions: ProbabilityOption[] = [
  'Très probable',
  'Probable',
  'Moyennement probable',
  'Peu probable',
  'Très peu probable'
];

export const riskMatrix: RiskMatrix = {
  'Mortalité': 5, 
  'Très probable': 5,
  'Grave': 4,
  'Probable': 4,
  'Moyennement grave': 3,
  'Moyennement probable': 3,
  'Mineure': 2,
  'Peu probable': 2,
  'Négligable': 1,
  'Très peu probable': 1,
};